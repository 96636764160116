import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import { NULL_VALUE } from 'later/utils/constants';
import { findFeaturelinkType } from 'later/utils/static-data/featurelink-content';

import type RouterService from '@ember/routing/router-service';
import type SessionService from 'later/services/-private/session';
import type ErrorsService from 'later/services/errors';
import type SegmentService from 'later/services/segment';
import type { UntypedService, Transition } from 'shared/types';

export default class FeaturelinkRoute extends Route {
  @service declare errors: ErrorsService;
  @service declare session: SessionService;
  @service declare userAgent: UntypedService;
  @service declare router: RouterService;
  @service declare segment: SegmentService;

  async beforeModel(transition: Transition): Promise<void> {
    this.session.requireAuthentication(transition, () => {
      this.session.setRedirectUrl(window.location.href);
    });

    const { feature, type, trigger } = this.#findFeatureName(transition);
    const featurelink = trigger === 'comment_count' ? 'conversations' : feature;

    if (feature === 'high_performing_post') {
      this.#trackHighPerformingPost(transition);
    }

    if (this.userAgent.device.isMobile) {
      this.#trackFeaturelink(feature);

      if (type !== 'mobile_available' && type !== 'default') {
        if (this.session.isAuthenticated) {
          this.router.transitionTo('mobile', {
            queryParams: {
              featurelink
            }
          });
        } else {
          this.router.transitionTo('user.login', {
            queryParams: {
              featurelink
            }
          });
        }
      }
    }
    this.session.setRedirectUrl(window.location.href);
  }

  #findFeatureName(transition: Transition): { feature: string | undefined; type: string; trigger: string | undefined } {
    const { localName } = transition.to;
    const isFeature = localName === 'feature';
    const isPartnerships = localName === 'partnerships';
    const feature = isFeature || isPartnerships ? transition.to.params.feature : localName;
    const trigger = transition.to.queryParams?.trigger;

    const featurelinkType = findFeaturelinkType(feature);

    return {
      feature,
      type: featurelinkType || 'default',
      trigger
    };
  }

  #trackFeaturelink(feature: string | undefined): void {
    if (!feature) {
      return;
    }

    this.segment.track('opened-feature-link', {
      location: feature?.concat('_feature_link')
    });
  }

  #trackHighPerformingPost(transition: Transition): void {
    const { post_id, trigger } = transition.to.queryParams;
    if (post_id && trigger) {
      this.segment.track('returned-to-app-from-trigger', {
        trigger: this.#getHighPerformingPostTrigger(trigger),
        source: 'email',
        secondary_source: trigger === 'comment_count' ? NULL_VALUE : 'open_post',
        is_mobile_device: this.userAgent.device.isMobile
      });
    } else {
      this.errors.log(`High-Performing email link missing parameters, post_id: ${post_id} or trigger: ${trigger}`);
    }
  }

  #getHighPerformingPostTrigger(trigger: string): string {
    if (trigger === 'comment_count') {
      return 'high_performing_comments';
    } else if (trigger.includes('like_count')) {
      return 'high_performing_likes';
    }

    return 'high_performing_reach';
  }
}
